<template>
  <div class="pa-0 pb-4 mx-lg-auto">
    <div>
        
  

      <div>  <v-img class="rounded-b-xl" height="320" src="img/design/market.png "  ><v-row class="pa-4">
     <v-btn icon plain @click="$router.go(-1)"> <v-icon >
        mdi-arrow-left
      </v-icon></v-btn><v-spacer/><v-btn icon onClick="window.location.reload();" ><v-icon left>
        mdi-refresh
      </v-icon></v-btn></v-row> <p    class="display-2 mt-n4 gray--text font-weight-thin text-center pb-12 "> {{this.input.toUpperCase()}}<v-icon x-large left> mdi-input </v-icon></p></v-img>  
        <v-container class="mt-n12">
      
          <v-row>  
            <v-col cols="10" sm="11">

              <buy-options-bar :items="items" />
            </v-col>
            <v-col cols="2" sm="1" class="ml-n1">
              <v-btn fab class="mt-1" small  @click="showAdvanced">
                <v-icon> mdi-tune </v-icon>
              </v-btn>
            </v-col>   
          </v-row> 
          <buy-input-bar :advanced="advanced" />
        </v-container>
     
        <div v-if="items[0]">
      <div class="pl-4" >
             <v-btn icon x-small to="/faq"
                ><v-icon  >mdi-information-outline</v-icon>
              </v-btn> <span class="caption" v-if="items[1]"> {{items.length}} {{input}} items available</span></div>
       
              <v-row class="pa-0 ma-0" >
          <v-col :key="i"
            cols="12"
            md="6" v-for="(item,i) in items" >
      
                <v-sheet class="fill-height" color="transparent"
                  ><v-lazy
                    v-model="isActive"
                    :options="{
                      threshold: 0.5,
                    }"
                    transition="fade-transition"
                  >
                    <buy-item-item-info :itemid="item.id" /></v-lazy
                ></v-sheet>
         
          </v-col>
          </v-row>
      
        </div>
       <v-card @click="clearList()" color="secondary lighten-3 "
          class="rounded-xl outlined elevation-1 text-center ma-4 pa-2"
          v-if="items.length < 1"
        >
          <v-card-text class="caption ">
            No watches to show. Use search / filters to find watches.
          </v-card-text><v-icon>
        mdi-refresh
      </v-icon>
        </v-card>
      </div>
    </div>
  </div>
</template>


<script>
import BuyItemItemInfo from "./BuyItemItemInfo.vue";
import BuySearchBar from "./BuySearchBar.vue";
import BuyOptionsBar from "./BuyOptionsBar.vue";
import BuyTagBar from "./BuyTagBar.vue";
//import SearchTag from "../views/SearchTag.vue";
export default {
  components: {
    BuyItemItemInfo,
    BuySearchBar,
    BuyOptionsBar,
    BuyTagBar,
    //SearchTag,
  },
  props: ["input"],
  data() {
    return {
      advanced: false,
      isActive: false,
    };
  },

  

  computed: {
  /*  itemsUnfiltered() {
      //this.$store.dispatch("setBuyItemList");
      return this.$store.getters.getBuyItemList;
    },*/

 itemsUnfiltered() {
     
let list = this.$store.getters.getBuyItemList || [];
console.log(list)
     if (list[0]) {
      return list;
    }else{this.$store.dispatch("setBuyItemList");
    return this.$store.getters.getBuyItemList;
    }},

     items() {
if( this.itemsUnfiltered){
       //return this.itemsUnfiltered.filter(item => item.inputs.find(inputs => inputs.toLowerCase().includes(this.input.toLowerCase())))
            return this.itemsUnfiltered.filter(item => item.description.toLowerCase().includes(this.input.toLowerCase()) || item.title.toLowerCase().includes(this.input.toLowerCase()) || item.tags.find(tag => tag.includes(this.input.toLowerCase))
      );}
    },
  },

  methods: {
    showAdvanced() {
      this.advanced = !this.advanced;

      this.$store.dispatch("setSortedTagList");
      this.$store.dispatch("setSortedLocationList");

    },
    clearList() {
   this.$store.dispatch("setBuyItemList");
  }
  },
};
</script>


